"use client";

import { useDecision } from "@optimizely/react-sdk";
import pluralize from "pluralize";
import { DepartureHit } from "../algolia-search/types/HitTypes";

export const useUrgencyBadgesExperiment = (
    badge: DepartureHit["departureStatusBadge"],
    nbSpotsLeft: number,
): DepartureHit["departureStatusBadge"] => {
    const [decision, clientReady] = useDecision("urgency_badges");
    const showExperiment = clientReady && decision.variationKey === "on";

    if (showExperiment && badge?.text.includes("left at this price")) {
        const splitText = badge.text.split(" ");
        const numberOfSpots = Number.parseInt(splitText[0]);
        if (!Number.isNaN(numberOfSpots) && numberOfSpots === nbSpotsLeft) {
            return {
                ...badge,
                text: `${numberOfSpots} ${pluralize(
                    "spot",
                    numberOfSpots,
                )} left`,
                variant: "info" as const,
            };
        }
    }
    return badge;
};
