"use client";

import { useDecision } from "@optimizely/react-sdk";
import { gtmSendOptimizelyEvent } from "@wojo/analytics";

export const useSendGtmOptimizelyEvent = (experimentKey: string) => {
    const [decision, clientReady] = useDecision(experimentKey);
    if (clientReady) {
        gtmSendOptimizelyEvent(
            decision.flagKey,
            decision.ruleKey,
            decision.variationKey,
        );
    }
};
